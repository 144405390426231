import {defineStore} from 'pinia'
import {
  CustomerActionRequest,
  ExchangeType,
  ReturnOptionEnum,
  SessionStorageKeys,
} from 'src/enums'
import {computed, ref, watch, type ComputedRef} from 'vue'
import type {
  LineItem,
  ElynSettings,
  HowToReturn,
  Order,
  OrderRaw,
  ReturnPolicy,
  SimilarProducts,
  Tenant,
  Variants,
  OrderCalculus,
  Product,
} from './interfaces'
import {OrderManager} from './managers/order'
import useTracking from './composables/useTracking'
import {useABTesting} from './composables/useABTesting'
import {ProductManager} from './managers/product'
import {LineItemManager} from './managers/lineitem'

export const useStore = defineStore('store', () => {
  const isLoggedIn = ref<boolean>(
    sessionStorage.getItem(SessionStorageKeys.accessToken) !== null,
  )
  const isAppLoading = ref<boolean>(true)
  const orderRaw = ref<OrderRaw | null>(null)
  const order: ComputedRef<Order | null> = computed(() => {
    if (!orderRaw.value) return null
    const isKeepAll = orderRaw.value.lineItems.every(
      item =>
        item.lastLineItemAmount.customerRequest === CustomerActionRequest.KEEP,
    )

    const lastStatus =
      orderRaw.value?.orderStatuses[orderRaw.value.orderStatuses.length - 1]

    if (lastStatus) {
      return {
        ...orderRaw.value,
        currentStatus: lastStatus.status,
        isKeepAll,
      }
    }
    return null
  })
  const returnPolicy = ref<ReturnPolicy | null>(null)
  const tenant = ref<Tenant | null>(null)
  const lineItem = ref<LineItem | null>(null)
  const productId = ref<string | null>(null)
  const variants = ref<Variants | null>(null)
  const isVariantExchangeAvailableForLineItem = computed(() => {
    if (
      !featuredReturnOptions.value.includes(ReturnOptionEnum.VARIANT_EXCHANGE)
    )
      return false
    if (!variants.value) return false

    const variantsRetrieved = variants.value.variants
    const isOnlyVariantAvailableIdentical =
      variantsRetrieved.length === 1 &&
      variantsRetrieved[0].variantId ===
        lineItem.value!.lineItemData.cmsVariantId

    if (
      variantsRetrieved.length === 0 ||
      isOnlyVariantAvailableIdentical ||
      !LineItemManager.hasVariantsWithStock(variantsRetrieved)
    ) {
      return false
    }

    return true
  })
  const isFetchingVariants = ref(false)
  const diffPriceProductsPreview = ref<Product[]>([])
  const isDiffPriceProductsPreviewFetching = ref(false)
  const howToReturn = ref<HowToReturn | null>(null)
  const similarProducts = ref<SimilarProducts | null>(null)
  const calculus = ref<OrderCalculus | null>(null)
  const isFetchingCalculus = ref(false)
  const elynSettings = ref<ElynSettings | null>(null)
  const exchangeType = ref<ExchangeType>(ExchangeType.VARIANTS)
  const featuredReturnOptions = ref<ReturnOptionEnum[]>([])
  const otherReturnOptions = ref<ReturnOptionEnum[]>([ReturnOptionEnum.REFUND])

  watch(
    [order, calculus, returnPolicy],
    ([newOrder, newCalculus, newReturnPolicy]) => {
      if (!newOrder || !newCalculus || !newReturnPolicy) return
      featuredReturnOptions.value = OrderManager.getOrderFeaturedReturnOptions(
        newOrder,
        newCalculus,
        newReturnPolicy,
      )
      otherReturnOptions.value = OrderManager.getOrderOtherReturnOptions(
        newOrder,
        newCalculus,
        newReturnPolicy,
      )
    },
  )

  watch([lineItem, productId], async ([newLineItem, newProductId]) => {
    if (!newLineItem || !newProductId || !order.value || !orderRaw.value) return
    if (
      featuredReturnOptions.value.includes(ReturnOptionEnum.VARIANT_EXCHANGE) ||
      exchangeType.value === ExchangeType.DIFF_PRICE
    ) {
      isFetchingVariants.value = true
      variants.value = await ProductManager.getProductVariant(
        order.value.id,
        newProductId,
        newLineItem.id,
        exchangeType.value,
      )
      isFetchingVariants.value = false

      // Updating order's list of line items to add the is_in_stock property
      const lineItemIndex = orderRaw.value.lineItems.findIndex(
        x => x.id === newLineItem.id,
      )
      if (lineItemIndex > -1) {
        if (variants.value?.variants.some(x => x.inventoryQuantity > 0)) {
          orderRaw.value.lineItems[lineItemIndex].lineItemData.isInStock = true
        } else if (lineItem.value) {
          orderRaw.value.lineItems[lineItemIndex].lineItemData.isInStock = false
        }
      }
    }
    if (
      tenant.value &&
      returnPolicy.value &&
      featuredReturnOptions.value.includes(
        ReturnOptionEnum.DIFF_PRODUCT_EXCHANGE,
      )
    ) {
      isDiffPriceProductsPreviewFetching.value = true
      diffPriceProductsPreview.value =
        await ProductManager.getPreviewsDiffProducts(
          tenant.value,
          order.value,
          newLineItem,
          returnPolicy.value,
        )
      isDiffPriceProductsPreviewFetching.value = false
    }
  })

  const tracking = useTracking()
  watch([order, tenant, returnPolicy], async () => {
    if (tenant.value && order.value) {
      tracking.identify(`${tenant.value.id}-${order.value.id}`, {
        tenantId: tenant.value.id,
        tenant: tenant.value.name,
        returnPoliciyId: returnPolicy.value?.id,
        isChildOrder: !!order.value.initialOrderId,
      })
    }
  })

  watch(
    () => tenant.value,
    (newTenant, oldTenant) => {
      if (!newTenant || oldTenant) return
      const abTesting = useABTesting()
      abTesting.initMetadatas(newTenant.name)
    },
  )

  const recalculate = async () => {
    try {
      if (order.value) {
        isFetchingCalculus.value = true
        calculus.value = await OrderManager.postCalculus(order.value)
      }
    } finally {
      isFetchingCalculus.value = false
    }
  }

  return {
    isAppLoading,
    isLoggedIn,
    orderRaw,
    order,
    returnPolicy,
    howToReturn,
    tenant,
    calculus,
    isFetchingCalculus,
    recalculate,
    lineItem,
    productId,
    variants,
    isFetchingVariants,
    isVariantExchangeAvailableForLineItem,
    diffPriceProductsPreview,
    isDiffPriceProductsPreviewFetching,
    similarProducts,
    elynSettings,
    featuredReturnOptions,
    otherReturnOptions,
    exchangeType,
  }
})
