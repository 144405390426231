import {BrowserTracing} from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {createPinia} from 'pinia'
import {SessionStorageKeys} from 'src/enums'
import {useStore} from 'src/store'
import {createApp} from 'vue'
import App from './App.vue'
import './assets/main.css'
import router from './router'
import mixpanel from 'mixpanel-browser'
import posthog from 'posthog-js'
import i18n from './i18n'

dayjs.extend(localizedFormat)
dayjs.locale('fr')

const app = createApp(App)
app.use(createPinia())
app.use(i18n)

mixpanel.init(import.meta.env.VITE_MIXPANEL_KEY, {
  debug: import.meta.env.VITE_ENVIRONMENT !== 'production',
  track_pageview: true,
  ignore_dnt: true,
  persistence: 'localStorage',
})

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
})

mixpanel.init(import.meta.env.VITE_MIXPANEL_KEY, {
  debug: import.meta.env.VITE_ENVIRONMENT !== 'production',
  track_pageview: true,
  ignore_dnt: true,
  persistence: 'localStorage',
})

posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
})

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  enabled: import.meta.env.VITE_ENVIRONMENT === 'production',
})

app.use(router)

const store = useStore()

// Watch for changes in the store and save to sessionStorage
store.$subscribe((mutation, state) => {
  if (state.orderRaw)
    sessionStorage.setItem(SessionStorageKeys.savedStore, JSON.stringify(state))
})

app.mount('#app')
