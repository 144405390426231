import {CustomerActionRequest} from 'src/enums'
import type {
  LineItem,
  Order,
  Product,
  Variant,
  VariantOption,
} from 'src/interfaces'

export const DEFAUT_SHOPIFY_VARIANT = 'Default Title'

export class LineItemManager {
  static createLineItemExchangeWithNewProduct(
    lineItem: LineItem,
    product: Product,
  ) {
    lineItem.exchangeLineItem = JSON.parse(JSON.stringify(lineItem)) as LineItem
    lineItem.exchangeLineItem.lineItemData.cmsProductId = product.productId
    lineItem.exchangeLineItem.lineItemData.cmsProductName = product.productTitle
    lineItem.exchangeLineItem.lineItemData.unitPrice = product.variantPrice
    lineItem.exchangeLineItem.lineItemData.unitPriceWithDiscount =
      product.variantPrice
    lineItem.exchangeLineItem.lineItemData.skuImageUrl = product.image
    lineItem.exchangeLineItem.lineItemData.variantConsolidated = ''
  }

  static createLineItemExchangeWithVariant(
    lineItem: LineItem,
    cmsProductId: string,
    variants: Variant[],
    variantOptions: VariantOption[],
  ) {
    const variantSelected = LineItemManager.getSelectedVariant(
      variants,
      variantOptions,
    )

    if (!variantSelected) return

    let variantConsolidated = variantSelected.options
      .map(o => o.value)
      .join(' / ')
    if (variantConsolidated === DEFAUT_SHOPIFY_VARIANT) variantConsolidated = ''

    if (!lineItem.exchangeLineItem) {
      lineItem.exchangeLineItem = JSON.parse(
        JSON.stringify(lineItem),
      ) as LineItem
    }
    lineItem.exchangeLineItem.lineItemData.cmsVariantId =
      variantSelected.variantId

    lineItem.exchangeLineItem.lineItemData.variantConsolidated =
      variantConsolidated

    lineItem.exchangeLineItem.lineItemData.unitPrice = variantSelected.price
    lineItem.exchangeLineItem.lineItemData.unitPriceWithDiscount =
      variantSelected.price
    lineItem.exchangeLineItem.lineItemData.unitDiscountAllocated = 0

    if (variantSelected.image) {
      lineItem.exchangeLineItem.lineItemData.skuImageUrl = variantSelected.image
    }
  }

  static getAllVariantOptions(variants: Variant[]): VariantOption[] {
    const res: VariantOption[] = []
    variants.forEach(variant => {
      variant.options.forEach(option => {
        const existingOption = res.find(
          o => o.name === option.name && o.value === option.value,
        )
        if (!existingOption) {
          res.push(option)
        }
      })
    })
    return res
  }

  static getOnlyOneValueOptions(options: VariantOption[]) {
    const nameCounts: {[name: string]: number} = {}

    options.forEach(option => {
      nameCounts[option.name] = (nameCounts[option.name] || 0) + 1
    })

    const uniqueOptions = options.filter(
      option => nameCounts[option.name] === 1,
    )

    return uniqueOptions
  }

  static getAvailableOptions(
    variants: Variant[],
    selectedOptions: VariantOption[],
  ): VariantOption[] {
    const variantsAvailable: Variant[] = []
    for (const variant of variants) {
      const variantOptions = variant.options

      const variantAvailable = selectedOptions.every(selectedOption =>
        variantOptions.some(
          variantOption =>
            variantOption.name === selectedOption.name &&
            variantOption.value === selectedOption.value,
        ),
      )

      if (variantAvailable && variant.inventoryQuantity > 0) {
        variantsAvailable.push(variant)
      }
    }
    return LineItemManager.getAllVariantOptions(variantsAvailable)
  }

  static getSelectedVariant(
    variants: Variant[],
    selectedOptions: VariantOption[],
  ) {
    const variantAvailable = variants.find((variant: Variant) =>
      variant.options.every(
        variantOption =>
          selectedOptions.map(so => so.name).includes(variantOption.name) &&
          selectedOptions.map(so => so.value).includes(variantOption.value),
      ),
    )

    if (variantAvailable) return variantAvailable
  }

  static hasDifferentPriceVariants(variants: Variant[]): boolean {
    if (variants.length === 0) return false
    const firstPrice = variants[0].price
    return variants.some(variant => variant.price !== firstPrice)
  }

  static hasVariantsWithStock(variants: Variant[]): boolean {
    if (variants.length === 0) return false
    return variants.some(variant => variant.inventoryQuantity > 0)
  }

  static changeExchangeTypeCustomerRequest(
    order: Order,
    isInstantExchange: boolean,
  ) {
    order.lineItems.forEach(lineItem => {
      if (
        lineItem.lastLineItemAmount.customerRequest ===
          CustomerActionRequest.EXCHANGE &&
        isInstantExchange
      ) {
        lineItem.lastLineItemAmount.customerRequest =
          CustomerActionRequest.INSTANT_EXCHANGE
      } else if (
        lineItem.lastLineItemAmount.customerRequest ===
          CustomerActionRequest.INSTANT_EXCHANGE &&
        !isInstantExchange
      ) {
        lineItem.lastLineItemAmount.customerRequest =
          CustomerActionRequest.EXCHANGE
      }
    })
  }
}
