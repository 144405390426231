import posthog from 'posthog-js'
import type {ABTestingFeaturesEnum} from 'src/enums'

export function useABTesting() {
  const initMetadatas = (tenantName: string) => {
    posthog.setPersonPropertiesForFlags({tenant: tenantName})
  }

  const handleFeature = (
    featureName: ABTestingFeaturesEnum,
    expectedValue: unknown,
    abTestingCallback: () => void,
    initialCallback: () => void,
  ): void => {
    const isEnabled = posthog.getFeatureFlag(featureName) === expectedValue
    if (isEnabled) {
      abTestingCallback()
    } else {
      initialCallback()
    }
  }

  return {
    initMetadatas,
    handleFeature,
  }
}
