import apiService from 'src/apiService'
import {ExchangeType} from 'src/enums'
import type {
  GetDifferentPriceProductsParams,
  GetProductsResponse,
  GetProductVariantsParams,
  LineItem,
  Order,
  Product,
  ReturnPolicy,
  SimilarProducts,
  Tenant,
  Variants,
} from 'src/interfaces'

export class ProductManager {
  static async getProductVariant(
    orderId: number,
    productId: string,
    lineItemId: number,
    exchangeType: ExchangeType,
  ): Promise<Variants | null> {
    const params: GetProductVariantsParams = {
      isDiffPriceExchange: exchangeType === ExchangeType.DIFF_PRICE,
      lineItemId: lineItemId,
      productId: productId,
    }
    try {
      return await apiService.getVariants(orderId, params)
    } catch {
      return null
    }
  }

  static async getPreviewsDiffProducts(
    tenant: Tenant,
    order: Order,
    lineItem: LineItem,
    returnPolicy: ReturnPolicy,
  ): Promise<Product[]> {
    let familyProducts: Product[] = []
    if (returnPolicy.hasDifferentPriceExchange) {
      const params: GetDifferentPriceProductsParams = {
        lineItemId: lineItem.id,
        limit: 4,
        page: 1,
        search: '',
        minPrice: 0,
        maxPrice: 0,
        categoryCmsIds: [],
        is_preview: true,
        orderBy: '',
      }
      if (lineItem && order && !returnPolicy.isPriceUpExchangeEnabled) {
        params.maxPrice = lineItem.lineItemData.unitPriceWithDiscount
      }
      await apiService
        .getDifferentPriceProducts(order.id, params)
        .then((differentPriceProductsResponse: GetProductsResponse) => {
          familyProducts = differentPriceProductsResponse.results
        })
    } else {
      await apiService
        .getSimilarProducts(
          tenant.id,
          4,
          lineItem.lineItemData.unitPriceWithDiscount,
        )
        .then((similarProduct: SimilarProducts) => {
          familyProducts = similarProduct.products
        })
    }
    return familyProducts
  }
}
