import mixpanel from 'mixpanel-browser'
import posthog from 'posthog-js'

export default function useTracking() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const identify = (userId: string, metadata: Record<string, any> = {}) => {
    mixpanel.identify(userId)
    mixpanel.people.set(metadata)

    posthog.identify(userId, metadata)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const track = (event: string, properties: Record<string, any> = {}) => {
    mixpanel.track(event, properties)
    posthog.capture(event, properties)
  }

  return {identify, track}
}
