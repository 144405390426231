import {createI18n} from 'vue-i18n'
import content from './content.json'

const i18n = createI18n({
  legacy: false,
  availableLocales: ['fr'],
  locale: 'fr',
  messages: {fr: content},
})

export default i18n
