<script lang="ts" setup>
  import '@adyen/adyen-web/dist/adyen.css'
  import {
    applyAuthTokenInterceptor,
    clearAuthTokens,
    getBrowserSessionStorage,
    type IAuthTokens,
    type TokenRefreshRequest,
  } from 'axios-jwt'
  import {storeToRefs} from 'pinia'
  import ApiService, {axiosInstance} from 'src/apiService'
  import {computed, onBeforeMount, watch} from 'vue'
  import {RouterView} from 'vue-router'
  import DevBanner from './components/DevBanner.vue'
  import LoaderComponent from './components/generics/LoaderComponent.vue'
  import router, {routes} from './router'
  import {useStore} from './store'
  import {DevEnvManager} from './utils/dev-env-manager'

  const {isAppLoading, isLoggedIn, tenant, order, elynSettings} =
    storeToRefs(useStore())

  onBeforeMount(async () => {
    const requestRefresh: TokenRefreshRequest = async (
      refreshToken: string,
    ): Promise<IAuthTokens | string> => {
      return ApiService.refreshToken(refreshToken)
        .then(response => response.access)
        .catch(error => {
          clearAuthTokens()
          router.push({name: routes.login.pathName})
          return Promise.reject(error)
        })
    }

    const getStorage = getBrowserSessionStorage
    applyAuthTokenInterceptor(axiosInstance, {requestRefresh, getStorage})
  })

  const style = computed(() => ({
    'background-image': `url(${tenant.value?.backgroundUrl})`,
  }))

  const isProdEnv = import.meta.env.VITE_ENVIRONMENT === 'production'
  const localizeKey = import.meta.env.VITE_LOCALIZE_KEY

  if (!isProdEnv) {
    DevEnvManager.init()
  }

  const changeBaseUrl = (newUrl: string) => {
    if (isProdEnv) return
    DevEnvManager.changeBaseUrl(newUrl)
  }

  if (localizeKey) {
    // eslint-disable-next-line no-undef
    Localize.initialize({
      key: localizeKey,
      rememberLanguage: true,
      autoApprove: true,
      disableWidget: true,
      retranslateOnNewPhrases: true,
    })
    // eslint-disable-next-line no-undef, @typescript-eslint/no-explicit-any
    Localize.on('setLanguage', function (data: any) {
      const locale = data.to
      if (isLoggedIn && locale && order.value) {
        ApiService.patchOrder(order.value.id, {
          language: locale.toUpperCase(),
        })
      }
    })
  }

  watch(
    () => tenant.value?.isTranslationEnabled,
    () => {
      if (tenant.value?.isTranslationEnabled) {
        // eslint-disable-next-line no-undef
        Localize.showWidget()
      } else {
        // eslint-disable-next-line no-undef
        Localize.setLanguage('fr')
      }
    },
    {immediate: true},
  )
</script>

<template>
  <DevBanner
    v-if="!isProdEnv"
    @change-base-url="changeBaseUrl($event)"
  />

  <LoaderComponent v-if="isAppLoading" />

  <div
    class="flex flex-col items-center bg-fixed bg-center bg-no-repeat bg-cover w-full min-h-full justify-between"
    :style="style"
  >
    <header
      class="flex justify-center items-center h-[50px] lg:h-20 bg-white w-screen mb-5"
    >
      <img
        :src="tenant?.logoWebPngUrl"
        class="lg:max-h-[60px] max-h-10"
        alt="logo"
      />
    </header>

    <div
      class="flex flex-col items-center px-[10px] h-full lg:max-w-none w-full"
    >
      <router-view :class="[{'lg:h-[710px]': isLoggedIn}]" />
    </div>

    <footer class="flex justify-between w-full p-[20px] self-end">
      <a
        :href="elynSettings?.faqUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="flex items-center m-0 h-10 bg-gray-300 hover:bg-gray-200 text-sm px-4 rounded-3xl opacity-80 transition-colors duration-200"
      >
        {{ $t('layout.need_help') }}
      </a>
      <a
        :href="elynSettings?.homepageUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="flex items-center m-0 h-10 bg-gray-300 hover:bg-gray-200 px-4 rounded-3xl opacity-80 transition-colors duration-200"
      >
        <span class="text-sm">{{ $t('layout.powered_by') }} </span>
        <span class="elyn-logo ml-1 text-2xl">elyn</span>
      </a>
    </footer>
  </div>
</template>

<style scoped>
  .elyn-logo {
    font-family: SpeakPro;
  }

  @media (min-width: 1024px) {
    header {
      display: flex;
      place-items: center;
      padding-right: calc(var(--section-gap) / 2);
    }

    nav {
      text-align: left;
      margin-left: -1rem;
      font-size: 1rem;

      padding: 1rem 0;
      margin-top: 1rem;
    }
  }
</style>
